// Ce fichier est le fichier d'inclusion des js depuis laravel 9.

import OpenRemoteModal from './OpenRemoteModal';
customElements.define('core-utils-open-remote-modal', OpenRemoteModal,{extends: 'a'});

import CoreModalVideo from './ModalVideo';
customElements.define('core-utils-modal-video', CoreModalVideo, {extends: 'div'});

import CoreInfiniteScroll from './InfiniteScroll';
customElements.define('core-utils-infinite-scroll', CoreInfiniteScroll,{extends: 'div'});

import DisableOnSubmit from './DisableOnSubmit';
customElements.define('core-utils-disable-on-submit', DisableOnSubmit,{extends: 'button'});

import GeneratePassword from './GeneratePassword';
customElements.define('core-utils-generate-password', GeneratePassword,{extends: 'a'});

import Map from './Map';
customElements.define('core-utils-map', Map,{extends: 'div'});

import LinkDataForm from './LinkDataForm';
customElements.define('core-utils-link-data-form', LinkDataForm,{extends: 'a'});

import CopyLinkToClipboard from './CopyLinkToClipboard';
customElements.define('core-utils-copy-link-to-clipboard', CopyLinkToClipboard,{extends: 'a'});

import ConfirmOnClick from './ConfirmOnClick';
customElements.define('core-utils-confirm-on-click', ConfirmOnClick,{extends: 'a'});

import DeleteOnConfirmClick from './DeleteOnConfirmClick';
customElements.define('core-utils-delete-on-confirm-click', DeleteOnConfirmClick,{extends: 'a'});

import AjaxOnClick from './AjaxOnClick';
customElements.define('core-utils-ajax-on-click', AjaxOnClick,{extends: 'a'});

import StarRating from './StarRating';
customElements.define('core-star-rating', StarRating,{extends: 'div'});