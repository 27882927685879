import {Requestor} from 'hegyd/core/resources/assets/js/utils/Requestor.js'
import Swal from "sweetalert2";

export default class DeleteOnConfirmClick extends HTMLAnchorElement
{
    constructor()
    {
        super();
    }

    connectedCallback()
    {
        this.addEventListener('click', e => {
                e.preventDefault();
                let self = this;
                let elementToRemove = this;
                // Si on a précisé un élément à supprimer, on le recupère
                if(this.dataset.target !== null){
                    elementToRemove = this.closest(this.dataset.target);
                }
                Swal({
                    animation: false,
                    customClass: 'animated fadeIn',
                    title: this.dataset.swalTitle,
                    html: this.dataset.swalText,
                    type: this.dataset.swalType || 'warning',
                    showCancelButton: this.dataset.swalCancelButton || true,
                    confirmButtonText: this.dataset.swalConfirm,
                    cancelButtonText: this.dataset.swalCancel,
                }).then( (result) => {
                    if(result.value){

                        let route = this.href;
                        let method = this.dataset.method ?? "POST";

                        if(route) {
                            var requestor = new Requestor();
                            requestor.sendRequest(route, method, {}, data => {
                                if(data.success && data.success == true){
                                    // On supprime l'element si confirmé dans le retour de l'appel
                                    elementToRemove.remove();
                                }
                            });
                        }else {
                            elementToRemove.remove();
                        }
                    }
                });

            });
    }
}