"use strict";

window.HegydCore = {
    handleConfirm: function () {
        $('[data-confirm]').bind('submit', function (el) {
            el.preventDefault();

            var form = $(this);

            swal({
                animation: false,
                customClass: 'animated fadeIn',
                title: form.attr("data-swal-title"),
                text: form.attr("data-swal-text"),
                type: form.attr("data-swal-type"),
                showCancelButton: true,
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
            }).then(function (result) {
                if (result.value
                ) {
                    // En cas de confirmation, on submit le formulaire normalement.
                    form.unbind('submit').submit();
                }
            });

        });
        $('[data-confirm="onclick"]').on('click', function (el) {
            el.preventDefault();

            var $this = $(this);

            swal({
                animation: false,
                customClass: 'animated fadeIn',
                title: $this.attr("data-swal-title"),
                html: $this.attr("data-swal-text"),
                type: $this.attr("data-swal-type"),
                showCancelButton: true,
                confirmButtonText: 'Oui',
                confirmButtonColor: '#FF004E',
                cancelButtonText: 'Non',
            }).then(function (result) {
                if (result.value
                ) {
                    window.location.href = $this.attr('href');
                }
            });
        });
    },

    forwardToPage: function (data) {
        if (data.hasOwnProperty('href')) {
            window.location = data.href;
        }
    },

    sendRequest: function (url, method, data, callbackSuccess, callbackError) {
        $.ajax({
            url: url,
            method: method,
            data: data,
            success: function (data) {
                if (callbackSuccess) {
                    callbackSuccess(data);
                }
            },
            error: function (data) {
                if (callbackError) {
                    callbackError(data);
                }
            }
        });
    },

    initToastr: function () {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-top-center",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };
    },

    initRadioButtons: function (elems) {

        if (!jQuery().iCheck)
            return;

        if (elems === undefined) {
            var elems = $('.icheckable, .js-radio');
        }

        elems.iCheck({
            checkboxClass: 'icheckbox_flat-blue',
            radioClass: 'iradio_flat-blue'
        });

        elems.on('ifChecked', function (event) {
            $(event.target).change();
        });
    },

    initDateMask: function () {
        $('.datemask.not-init').each(function () {
            var $this = $(this);
            $this.inputmask();
            $this.removeClass('not-init');
        });
    },

    initDatePicker: function () {
        $('.datepicker.not-init').each(function () {

            var $container = $(this);
            var $input = $container.find('input');

            var data = {
                format: $container.data('format'),
                language: 'fr',
                rtl: false,
                orientation: 'bottom auto',
                autoclose: true,
                zIndexOffset: 999
            };

            var default_date = $container.data('default-date');
            if (typeof default_date !== 'undefined') {
                data.defaultViewDate = default_date;
            }
            var start_date = $container.data('start-date');
            if (typeof start_date !== 'undefined') {
                data.startDate = start_date;
            }

            if ($input.data('allowClear'))
                data.clearBtn = true;

            if ($input.data('todayBtn'))
                data.todayBtn = $input.data('todayBtn');

            $container.datepicker(data);

            $container.removeClass('not-init');

        });
    },

    initDateTimePicker: function () {

        const flatpickr = require('flatpickr');
        const French = require("flatpickr/dist/l10n/fr.js").default.fr;

        const $datetimepickers = $.makeArray($('.datetimepicker.not-init input'));

        for (let i = 0; i < $datetimepickers.length; i++) {

            let $datetimepicker = $($datetimepickers[i]);

            $datetimepicker.flatpickr({
                dateFormat: "d/m/Y H:i",
                locale: French,
                allowInput: true,
                position: "below left",
                monthSelectorType: 'static',
                minDate: $datetimepicker.data('minDate'),
                maxDate: $datetimepicker.data('maxDate'),
                mode: $datetimepicker.data('flatpickrMode') ? $datetimepicker.data('flatpickrMode') : "single",
                enableTime: true,
                time_24hr: true
            });

            $datetimepicker.removeClass('not-init');
        }

    },

    initColorPicker: function () {

        $('.colorpicker.not-init').each(function () {

            var $this = $(this);

            var data = {};

            if ($this.data('mode'))
                data.mode = $this.data('mode');

            $this.asColorPicker(data);

            $this.removeClass('not-init');

        });

    },

    /**
     * Initialize Select2.js items. No options required to initialise a select
     * @param selector
     * @param options_forced
     */
    initSelect2: function (selector, options_forced) {

        selector = typeof selector !== 'undefined' ? selector : 'select.selectable2, select.select2';
        options_forced = typeof options_forced !== 'undefined' ? options_forced : {};

        var options_default = {
            language: 'fr'
        };
        $.extend(options_default, options_forced);

        var $selector = $(selector);

        if ($selector !== undefined && $selector.length) {
            if ($selector.length > 1) {
                $selector.each(function () {
                    HegydCore.initSelect2Item($(this), options_default);
                });

            } else {
                HegydCore.initSelect2Item($selector, options_default);
            }
        }
    },

    configSelect2AllNone: function ($selector, default_options) {
        $.fn.select2.amd.require([
            'select2/utils',
            'select2/dropdown',
            'select2/dropdown/attachBody'
        ], function (Utils, Dropdown, AttachBody) {
            function SelectAll() {
            }

            SelectAll.prototype.render = function (decorated) {
                var $rendered = decorated.call(this);
                var self = this;

                var $selectAll = $('<a/>')
                    .addClass('btn btn-info col-md-6')
                    .text('Tous')
                    .prepend('<i class="fas fa-plus"></i> ');

                var $selectNone = $('<a/>')
                    .addClass('btn btn-danger col-md-6')
                    .text('Aucun')
                    .prepend('<i class="fas fa-times"></i> ');

                var $div = $('<div/>')
                    .addClass('btn-in-select2')
                    .append($selectAll)
                    .append($selectNone);

                var checkOptionsCount = function () {
                    var count = $('.select2-results__option').length;
                    $selectAll.prop('disabled', count > 25);
                };

                var $container = $('.select2-container');
                $container.bind('keyup click', checkOptionsCount);

                var $dropdown = $rendered.find('.select2-dropdown');

                $dropdown.prepend($div);
                $selectAll.on('click', function (e) {
                    $('option', self.$element).prop('selected', true);
                    self.$element.trigger('change');
                    self.trigger('close');
                });

                $selectNone.on('click', function (e) {
                    // Trigger the select event
                    self.$element.val(null);
                    self.$element.trigger('change');
                    self.trigger('close');
                });

                return $rendered;
            };
            var options = $.extend(default_options, {
                dropdownAdapter: Utils.Decorate(
                    Utils.Decorate(
                        Dropdown,
                        AttachBody
                    ),
                    SelectAll
                )
            });
            $selector.select2(options);
        });
    },
    formatSelect2Icon: function (icon) {
        return $('<a>').addClass('resources').html('<i class="' + icon.text + '"></i> ' + icon.text);
    },
    initSelect2Item: function ($selector, options) {
        var selectableIcons = $($selector).hasClass('selectable2-icons');
        if (selectableIcons) {
            options = {
                ...options,
                templateSelection: HegydCore.formatSelect2Icon,
                templateResult: HegydCore.formatSelect2Icon
            }
        }
        // Manage default placeholder
        var placeholder = $selector.data('placeholder');
        if (placeholder !== undefined && placeholder.length > 0) {
            options = $.extend(options, {'placeholder': placeholder});
        }

        options.allowClear = $selector.attr('required') === undefined;

        if ($selector.data('all-none-option')) {
            HegydCore.configSelect2AllNone($selector, options);
        } else {
            $selector.select2(options);
        }

        // Is selected items are available in data-selected as array then force them
        var selected = $selector.data('selected');
        if (selected !== undefined && selected.length > 0) {
            $("div" + $selector).select2('data', selected);
        }
    },

    /**
     * Initialize Summernote WYSIWYG
     * @param selector      Force jQuery  selector (default : .summernoteable)
     */
    initSummernote: function (selector) {

        if ($.summernote === undefined) {
            return;
        }

        selector = typeof selector !== 'undefined' ? selector : '.summernoteable,textarea.summernote';

        var $selector = $(selector);

        if ($selector != undefined && $selector.length) {
            if (typeof $selector.summernote === "function") {
                $selector.summernote({
                    toolbar: [
                        // [groupName, [list of button]]
                        ['style', ['style']],
                        ['fontstyle', ['bold', 'italic', 'underline', 'clear']],
                        ['fontname', ['fontname']],
                        ['fontsize', ['fontsize']],
                        ['color', ['color']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['height', ['height']],
                        ['table', ['table']],
                        ['insert', ['link', 'picture']],
                    ],
                    height: 250,
                    disableDragAndDrop: true,
                    lang: 'fr-FR'
                });

                return $selector;
            } else {
                console.error('Summernote library is not available, not loaded properly');
            }
        }
    },

    /**
     * Initialize Ckeditor WYSIWYG
     * @param selector      Force jQuery  selector (default : .ckeditorable)
     */
    initCkeditor: function (selector) {

        if ($.ckeditor === undefined) {
            return;
        }

        selector = typeof selector !== 'undefined' ? selector : '.ckeditorable,textarea.ckeditor';

        var $selector = $(selector);

        if ($selector != undefined && $selector.length) {
            if (typeof $selector.ckeditor === "function") {
                $selector.ckeditor();

                return $selector;
            } else {
                console.error('Ckeditor library is not available, not loaded properly');
            }
        }
    },

    initPhoneField: function (selector) {
        if ($.fn.intlTelInput === undefined) {
            return;
        }

        if (selector === undefined) {
            selector = '.js-phone-field';
        }

        $(selector).intlTelInput({
            utilsScript: "/vendor/hegyd/core/dependencies/intl-tel-input/build/js/utils.js",
            initialCountry: 'auto',
            preferredCountries: ['fr'],
            geoIpLookup: function (callback) {
                $.get("https://ipinfo.io", function () {
                }, "jsonp").always(function (resp) {
                    var countryCode = (resp && resp.country) ? resp.country : "";
                    callback(countryCode);
                });
            }
        });

        $(selector).each(function () {
            var $input = $(this);
            var $form = $input.parents('form');

            $form.on('submit', function () {
                $input.val($input.intlTelInput('getNumber'));
            });
        })
    },

    initRemoteModal: function (selector, remoteUrl, afterLoadedCallback, successCallback, errorCallback, ajaxSubmitForm) {
        var $modal = $(selector);

        if (ajaxSubmitForm === undefined) {
            ajaxSubmitForm = true;
        }

        if (!$modal.length) {
            console.error('Modal not found !');
            return false;
        }

        $modal.on('hidden.bs.modal', function (e) {
            let target = $(e.target);
            $(target).find('.modal-content:not(.static-content)').empty();
        });

        $('.modal-content', $modal).load(remoteUrl, function () {
            if (afterLoadedCallback != undefined) {
                afterLoadedCallback();
            }

            if (ajaxSubmitForm) {
                var $form = $modal.find('form');
                var $loader = $modal.find('.loading');

                if($form.length) {
                    window.HegydCore.initFormValidate($('.modal-content', selector));
                }

                if (!$.fn.ajaxForm) {
                    return;
                }

                var ajaxConfig = {
                    beforeSubmit: function (arr, $form, option) {
                        if ($form.attr('data-confirm') !== undefined && $form.attr('data-confirm') !== 'confirmed') {
                            swal({
                                animation: false,
                                customClass: 'animated fadeIn',
                                title: $form.attr("data-swal-title"),
                                text: $form.attr("data-swal-text"),
                                type: $form.attr("data-swal-type"),
                                showCancelButton: true,
                                confirmButtonText: 'Oui',
                                cancelButtonText: 'Non'
                            }).then(function (result) {
                                if (result.value) {
                                    // En cas de confirmation, on submit le formulaire normalement.
                                    $form.attr('data-confirm', 'confirmed');

                                    $form.submit();

                                    $loader.removeClass('hide');
                                }
                            });

                            return false;
                        }

                        $('form', $modal).addClass('hide');

                        $loader.removeClass('hide');
                    },
                    success: function (data) {
                        $modal.modal('hide');

                        $loader.addClass('hide');

                        if (successCallback != undefined) {
                            successCallback(data);
                        }
                    },
                    error: function (data, error, msg) {
                        if (errorCallback != undefined) {
                            errorCallback(data);
                        }

                        $loader.addClass('hide');

                        $('form', $modal).removeClass('hide');

                        var $error = $('<div/>').addClass('alert alert-block alert-danger')
                            .html('' +
                                '<button data-dismiss="alert" class="close close-sm" type="button">' +
                                '   <i class="fas fa-times"></i>' +
                                '</button>' + HegydCore.getModalOutputData(data.responseJSON)
                            );

                        $form.prepend($error);
                    }
                };

                $form.ajaxForm(ajaxConfig);
            }
        });

        $modal.modal('show');
    },

    getModalOutputData: function (data) {

        if (typeof data !== 'undefined') {

            var list = data;

            if (data.hasOwnProperty('errors'))
                list = data.errors;

            var html = '<ul>';
            $.each(list, function (key, item) {
                html += '<li>' + item + '</li>';
            });
            html += '</ul>';

            return html;
        }

        return 'Un problème est survenu lors de l\'enregistrement';
    },

    initFlashAlert: function () {
        if ($('[name="flash-alert"]').length) {
            $('[name="flash-alert"]').each(function () {
                var $that = $(this);
                swal($that.data('title'), $that.data('content'), $that.data('type'));
            });
        }
    },

    initAjaxRequestHeader: function () {
        $.xhrPool = []; // array of uncompleted requests
        $.xhrPool.abortAll = function () { // our abort function
            $(this).each(function (idx, jqXHR) {
                jqXHR.abort();
            });
            $.xhrPool.length = 0
        };
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': csrf_token
            },
            beforeSend: function (jqXHR) { // before jQuery send the request we will push it to our array
                $.xhrPool.push(jqXHR);
            },
            complete: function (jqXHR) { // when some of the requests completed it will splice from the array
                var index = $.xhrPool.indexOf(jqXHR);
                if (index > -1) {
                    $.xhrPool.splice(index, 1);
                }
            }
        });
    },

    initFormValidate: function ($parent) {

        var $selector = $('form.js-validate');

        if ($parent && $parent !== undefined) {
            $selector = $('form.js-validate', $parent);
        }

        if (jQuery.validator === undefined || !$selector.length) {
            return;
        }

        // This will set `ignore` for all validation calls
        jQuery.validator.setDefaults({
            // This will ignore all hidden elements alongside `contenteditable` elements
            // that have no `name` attribute
            ignore: ":hidden:not(.summernoteable,textarea.summernote),.note-editable"
        });

        jQuery.validator.addMethod('ckrequired', function (value, element) {
            var idname = $(element).attr('id');
            var editor = CKEDITOR.instances[idname];

            if (editor !== undefined) {
                var ckValue = GetTextFromHtml(editor.getData()).replace(/<[^>]*>/gi, '').trim();
                if (ckValue.length === 0) {
                    //if empty or trimmed value then remove extra spacing to current control
                    $(element).val(ckValue);
                } else {
                    //If not empty then leave the value as it is
                    $(element).val(editor.getData());
                }
                return $(element).val().length > 0;
            }

            return true;
        }, jQuery.validator.messages.required);

        function GetTextFromHtml(html) {
            var dv = document.createElement("DIV");
            dv.innerHTML = html;
            return dv.textContent || dv.innerText || "";
        }

        jQuery.validator.addMethod('dateFR', function (value, element) {
            return this.optional(element) || /^(([0-2]\d|[3][0-1])[\/\-]([0]\d|[1][0-2])[\/\-]\d{4})$/.test(value);
        }, jQuery.validator.messages.date);

        jQuery.validator.addMethod("exactlength", function (value, element, param) {
            return this.optional(element) || value.length == param;
        }, $.validator.format("Veuillez renseigner {0} caractères."));

        jQuery.validator.addMethod('validatePhone', function (value, element) {
            // On récupère le composant parent pour pouvoir récupérer l'instance de intl-tel-input, utilisée pour la validation
            const component = element.closest("[is='core-item-phone']");
            return this.optional(element) || component.getItiInstance().isValidNumber();
        }, "Numéro non valide");

        jQuery.validator.addMethod("maxcontent", function (value, element, param) {
            var value = value.replace(/<\/?[^>]+(>|$)/g, "");
            return this.optional(element) || value.length <= param;
        }, $.validator.format("Veuillez renseigner maximum {0} caractères."));


        $.each($selector, function (i, form) {
            var form_validate = $(form).validate({
                // validation rules for registration form
                errorClass: "invalid-feedback",
                errorElement: 'div',
                errorPlacement: function (error, element) {
                    var errorBlock = element.parents('.form-group').find('.help-block');

                    if (!errorBlock.length) {
                        if (element.parent('.input-group').length) {
                            error.insertAfter(element.parent());
                        } else {
                            error.insertAfter(element);
                        }
                    }

                    errorBlock.append(error);
                },
                // The div has the following class `.note-editable .panel-body` that we can use to
                // exclude it from validation
                invalidHandler: function (event, validator) {
                    // 'this' refers to the form
                    var errors = validator.numberOfInvalids();
                    if (errors) {
                        var message = errors == 1
                            ? 'Un champ n\'est pas valide, il est entouré de rouge'
                            : errors + ' champs ne sont pas valides. Ils sont entourés de rouge';
                        toastr.error(message);
                    }
                },
                success: function (label, element) {
                    $(element).parents('.invalid-feedback').removeClass('invalid-feedback');
                },
                highlight: function (element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass('has-error');
                    $(element).addClass("is-invalid").removeClass("is-valid");
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).parents('.form-group').removeClass('has-error');
                    $(element).addClass("is-valid").removeClass("is-invalid");
                }

            });

            var $summernote = HegydCore.initSummernote($('.summernoteable,textarea.summernote', $(form)));

            if ($summernote !== undefined) {
                $summernote.on('summernote.change', function (event, contents) {
                        var $this = $(this);
                        $this.val($this.summernote('isEmpty') ? "" : contents);
                        form_validate.element($this);
                });
            }
        });
    },

    initDataForm: function () {
        $('body').on('click', '[data-form]', function (evt) {
            evt.preventDefault();
            var self = $(this);

            var csrf_token = $('meta[name="csrf-token"]').attr('content');

            self.append(function () {

                if (self.find('form').length)
                    return;

                return "\n" +
                    "<form action='" + self.attr('href') + "' method='POST' style='display:none;'>\n" +
                    "<input type='hidden' name='_token' value='" + csrf_token + "' />" +
                    "	<input type='hidden' name='_method' value='" + self.data('form') + "'>\n" +
                    "</form>";

            });

            self.removeAttr('href');
            self.attr('style', 'cursor:pointer');

            var ajax = $(this).data('ajax') || false;

            var action = $(this).data('action') || undefined;
            var containerId = $(this).data('callbackContainerId') || undefined;
            var container;
            var promise;

            if(containerId !== undefined)
            {
                container = document.getElementById(containerId);
            }else{
                container = window;
            }

            var ajaxCall = {
                success: function (data) {
                    if (action !== undefined){
                        container[action](self, data);
                    }
                },
                complete: function (data) {
                    promise();
                }
            };

            if ($(this).data('confirm') === undefined || $(this).data('confirm') === true) {
                var text = $(this).data('text') || "L'action sera irréversible";

                swal({
                    animation: false,
                    customClass: 'animated fadeIn',
                    title: 'Êtes-vous sûr ?',
                    allowOutsideClick: true,
                    text: text,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: "#D2282D",
                    cancelButtonText: 'Annuler',
                    confirmButtonText: "Oui !",
                    showLoaderOnConfirm: true,
                    preConfirm: function () {
                        return new Promise(function (resolve) {
                            if (ajax) {
                                promise = resolve;

                                $('form', self).ajaxForm(ajaxCall);
                            }

                            $('form', self).submit();
                        });
                    }
                }).then(function () {
                    swal.close();
                    $('.modal').modal('hide');
                });

            } else {
                if (ajax) {
                    $('form', $(this)).ajaxForm(ajaxCall);
                }

                $('form', $(this)).submit();
            }

            $(this).trigger('complete');

            return false;
        });
    },

    initFileInput: function () {
        $.each($('input.unique-image-file-input'), function () {
            var $this = $(this);

            var data = {
                theme: 'fas',
                language: 'fr',
                showCaption: false,
                showUpload: false,
                allowedFileTypes: ['image'],
                fileTypeSettings: {
                    image: function (vType, vName) {
                        return vType.match('image.*');
                    },
                },
                fileActionSettings: {
                    showDrag: false
                }
            };

            if ($this.data('show-remove') == 'false') {
                data.showRemove = false;
            }

            $this.on('drop', function (e) {
                if (e.originalEvent.dataTransfer) {
                    if (e.originalEvent.dataTransfer.files.length) {
                        // Stop the propagation of the event
                        e.preventDefault();
                        e.stopPropagation();
                        // Main function to upload
                        $(this)[0].files = e.originalEvent.dataTransfer.files;
                    }
                }
            });

            $this.on('filecleared', function (event) {
                if ($(this).data('js-required') == 'required') {
                    $(this).attr('required', 'required');
                }
            });

            $this.fileinput(data);

        });
        $.each($('input.unique-file-file-input'), function () {
            var $this = $(this);

            var data = {
                theme: 'fas',
                language: 'fr',
                showCaption: false,
                showUpload: false,
                showDrag: false,
                fileActionSettings: {
                    showZoom: false,
                    showUpload: false,
                    showDrag: false
                }
            };

            if ($this.data('allowed-format')) {
                data.allowedFileExtensions = $this.data('allowed-format');
            }

            if ($this.data('show-remove') == 'false') {
                data.showRemove = false;
                data.fileActionSettings.showRemove = false;
            }

            $this.on('drop', function (e) {
                if (e.originalEvent.dataTransfer) {
                    if (e.originalEvent.dataTransfer.files.length) {
                        // Stop the propagation of the event
                        e.preventDefault();
                        e.stopPropagation();
                        // Main function to upload
                        $(this)[0].files = e.originalEvent.dataTransfer.files;
                    }
                }
            });

            $this.on('filecleared', function (event) {
                if ($(this).data('js-required') == 'required') {
                    $(this).attr('required', 'required');
                }
            });

            $this.fileinput(data);

        });

        $.each($('input.unique-video-file-input'), function () {
            var $this = $(this);

            var data = {
                theme: 'fas',
                language: 'fr',
                showCaption: false,
                showUpload: false,
                showDrag: false,
                allowedFileTypes: ['video']
            };

            if ($this.data('show-remove') == 'false') {
                data.showRemove = false;
            }

            $this.on('drop', function (e) {
                if (e.originalEvent.dataTransfer) {
                    if (e.originalEvent.dataTransfer.files.length) {
                        // Stop the propagation of the event
                        e.preventDefault();
                        e.stopPropagation();
                        // Main function to upload
                        $(this)[0].files = e.originalEvent.dataTransfer.files;
                    }
                }
            });

            $this.on('filecleared', function (event) {
                if ($(this).data('required') == 'required') {
                    $(this).attr('required', 'required');
                }
            });

            $this.fileinput(data);

        });


        $('button.fileinput-remove, button.kv-file-remove').on('click', function () {
            var $formgroup = $(this).parents('.form-group');
            var $input = $('<input/>', {
                name: $formgroup.data('field') + '-removed',
                type: 'hidden',
                value: 1
            });
            $(this).parents('form').append($input);
            $('input.unique-image-file-input', $formgroup).fileinput('clear');
            $('input.unique-file-file-input', $formgroup).fileinput('clear');
            $('input.unique-video-file-input', $formgroup).fileinput('clear');
        });
    },

    /**
     * Define a logger that wil be enable only on dev mode or on demand for prod debug
     * @param data
     */
    log: function (data) {
        if (enableHegydConsoleLog !== undefined && enableHegydConsoleLog) {
            console.log(data);
        }
    },

    // TODO : Ajout des remplacements de lettres accentuées
    slugify: function (text) {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')
            .replace(/--+/g, '-')
            .replace(/^-+/, '-')
            .replace(/\_+/, '-')
            .replace(/-+$/, '-')
            ;
    },

    init: function () {
        this.initAjaxRequestHeader();
        this.initToastr();
        this.initRadioButtons();
        this.initSelect2();
        this.initSummernote();
        this.initFlashAlert();
        this.initFormValidate();
        this.initDataForm();
    }
};

$(document).ready(function () {
    HegydCore.init();

    /**
     * Search given named buttons into given form to deactivate them
     * Bould be extended to all submit buttons (to test...)
     * This allow to avoid double click causing trouble by creating dupplicates entries in create forms
     */
    function disableFormSaveButtons($form, name) {

        if ($form != undefined && $form.is('form')) {

            var $button = $form.find('[name=' + name + ']');

            if ($button != undefined) {

                if ($button.length != undefined && $button.length > 1) {
                    for (var i = 0, item; item = $button[i]; i++) {
                        var $item = $(item);
                        if ($item != undefined && $item.length > 0)
                            $item.attr('disabled', true);
                    }
                } else {
                    $button.attr('disabled', true);
                }

            }

        } else {
            console.error('$form is not a form');
        }

    }

    /**
     * Listen each submit event to disable save buttons if exists
     * This allow to avoid double click causing trouble by creating dupplicates entries in create forms
     */
    $('body').on('submit', 'form', function (e) {
        // Seems to block normal process...
        //e.preventDefault();

        var $form = $(this);

        disableFormSaveButtons($form, 'save');
        disableFormSaveButtons($form, 'save_and_continue');
        disableFormSaveButtons($form, 'save-and-close');
        disableFormSaveButtons($form, 'save-and-new');

        var $btn = $("button[type=submit][clicked=true]");
        if ($btn.length) {
            var $_action = $('input[name=_action]', $(this));
            if ($_action.length) {
                $_action.val($btn.attr('name'));
            }
        }
    });

    $('body').on('click', 'form button[type=submit]', function (e) {
        $('button[type=submit]', $(this).parents('form')).removeAttr('clicked');
        $(this).attr('clicked', true);
    });
});
